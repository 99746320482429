/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { includes } from 'lodash';
import shortid from 'shortid';

// Import Ant Design components
import { Tooltip } from 'antd';
import { EllipsisOutlined, CheckCircleOutlined, WifiOutlined } from '@ant-design/icons';

// Import components
import IconFont from '../../components/IconFont';

// Import stylesheet
import styles from './styles';

export const colorMappings = {
    CHANNEL_OFF: '#6b6b6b',
    NO_STORAGE: '#6b6b6b',
    STORAGE_FORMAT_REQUIRED: '#d4b106',
    NOT_RECORDING: '#d4b106',
    SIGNAL_LOSS: '#cc6666',
    RECORDING: 'green',
};

export const channelsRender = (rec, vloss = '', service = '', hdd = '') => {
    // Parse recording, video loss, open channel and hdd status
    const recordingStatus = rec ? rec.split('|') : [];
    const vlossStatus = vloss && vloss !== '0' ? vloss.split('|') : [];
    const channelStatus = (service?.split('|') ?? []).reduce(
        (prev, current) =>
            current.startsWith('CH:') ? current.replace('CH:', '').split(',') : prev,
        []
    );
    const hddStatus = hdd?.split('|')?.[0];
    const sdStatus = hdd?.split('|')?.[1];

    const getBgColor = (channel) => {
        const isSDCard = channel.startsWith('S');
        const channelIdx = isSDCard ? channel.replace('S', '') : channel;

        // For channel off status
        if (!channelStatus.includes(channelIdx)) return colorMappings.CHANNEL_OFF;

        // For no storage (H0 or S0) status
        if ((!isSDCard && hddStatus === 'H0') || (isSDCard && sdStatus === 'S0')) {
            return colorMappings.NO_STORAGE;
        }

        // For write error (H2 or S2) status
        if ((!isSDCard && hddStatus === 'H2') || (isSDCard && sdStatus === 'S2')) {
            return colorMappings.STORAGE_FORMAT_REQUIRED;
        }

        // For video loss status
        if (vlossStatus.includes(channelIdx)) return colorMappings.SIGNAL_LOSS;

        // For recording status
        return recordingStatus.includes(channel)
            ? colorMappings.RECORDING
            : colorMappings.NOT_RECORDING;
    };

    const channelStyles = {
        border: '1px solid #f0f0f0',
        color: 'white',
        width: '11%',
        textAlign: 'center',
        borderRadius: '5px',
    };

    return (
        <div>
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('1') }}>1</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('2') }}>2</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('3') }}>3</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('4') }}>4</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('5') }}>5</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('6') }}>6</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('7') }}>7</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('8') }}>8</div>
            </div>

            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S1') }}>S1</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S2') }}>S2</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S3') }}>S3</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S4') }}>S4</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S5') }}>S5</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S6') }}>S6</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S7') }}>S7</div>
                <div css={{ ...channelStyles, backgroundColor: getBgColor('S8') }}>S8</div>
            </div>
        </div>
    );
};

export const statusRender = (device, storageOnly = false, showText = false) => {
    // Set icon color based on device's online status
    const color = device.online === 'online' ? 'green' : 'red';

    // Initialization
    let netMode = <IconFont type="iconic_network" css={{ color }} />;
    let gps = <IconFont type="iconic_location" css={{ color: 'red' }} />;
    let sd = <IconFont type="iconic_sd" css={{ color: 'red' }} />;
    let hdd = <IconFont type="iconic_disk" css={{ color: 'red' }} />;

    // NetMode
    // switch (device.netMode) {
    //     case 'ETHERNET':
    //         netMode = <IconFont type="iconic_network" css={{ color }} />;
    //         break;
    //     case 'WWAN':
    //         netMode = <IconFont type="iconic_network" css={{ color }} />;
    //         break;
    //     case 'WIFI':
    //         netMode = <IconFont type="iconic_network" css={{ color }} />;
    //         break;
    //     default:
    //         break;
    // }

    // GPS
    switch (device.gpsFix) {
        case 0:
        case 1:
        case 2:
            gps = <IconFont type="iconic_location" css={{ color: '#d4b106' }} />;
            break;
        case 3:
            gps = <IconFont type="iconic_location" css={{ color: 'green' }} />;
            break;
        default:
            break;
    }

    // Storage
    if (device.hdd) {
        const hddStatus = device.hdd.split('|');
        if (hddStatus.length === 4) {
            if (hddStatus[0] === 'H1') {
                hdd = <IconFont type="iconic_disk" css={{ color: colorMappings.RECORDING }} />;
            } else if (hddStatus[0] === 'H2') {
                hdd = (
                    <IconFont
                        type="iconic_disk"
                        css={{ color: colorMappings.STORAGE_FORMAT_REQUIRED }}
                    />
                );
            }
            if (hddStatus[1] === 'S1') {
                sd = <IconFont type="iconic_sd" css={{ color: colorMappings.RECORDING }} />;
            } else if (hddStatus[1] === 'S2') {
                sd = (
                    <IconFont
                        type="iconic_sd"
                        css={{ color: colorMappings.STORAGE_FORMAT_REQUIRED }}
                    />
                );
            }
            if (hddStatus[2] === 'WF1') {
                netMode = <WifiOutlined style={{ color: 'green' }} />;
            }
        }
    }

    if (!showText) {
        return (
            <div css={{ fontSize: '16px', lineHeight: '1.2' }}>
                {!storageOnly ? (
                    <div>
                        <Tooltip title="Network">{netMode}</Tooltip>{' '}
                        <Tooltip title="GPS">{gps}</Tooltip>
                    </div>
                ) : null}
                <div>
                    <Tooltip title="Hard Drive">{hdd}</Tooltip>{' '}
                    <Tooltip title="SD Card">{sd}</Tooltip>
                </div>
            </div>
        );
    }

    return (
        <div css={{ fontSize: '16px', lineHeight: '1.2' }}>
            {!storageOnly ? (
                <div>
                    {netMode} Network {gps} GPS
                </div>
            ) : null}
            <div>
                {hdd} Hard Drive {sd} SD Card
            </div>
        </div>
    );
};

export const vlossRender = (vloss) => {
    if (vloss) {
        const vlossStatus = vloss.split('|');
        return includes(vlossStatus, '0') ? (
            <CheckCircleOutlined theme="filled" style={{ color: '#018001' }} />
        ) : (
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '21px',
                    fontSize: '11px',
                }}
            >
                {vlossStatus.map((vloss) => {
                    return (
                        <div key={shortid.generate()} css={styles.chErr}>
                            {vloss}
                        </div>
                    );
                })}
            </div>
        );
    }
    return <EllipsisOutlined />;
};
