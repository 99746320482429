/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment-timezone';
import Lightbox from 'react-image-lightbox';
import { useState, useEffect, useContext } from 'react';
import { saveAs } from 'file-saver';
import uuid4 from 'uuid/v4';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';

// Import Ant Design components
import {
    Modal,
    Button,
    Descriptions,
    Typography,
    Alert,
    Select,
    Divider,
    Space,
    Spin,
    Progress,
    Popover,
    Timeline,
    Badge,
    notification,
    message,
    Tooltip,
} from 'antd';
import {
    FieldTimeOutlined,
    ExclamationCircleOutlined,
    DownloadOutlined,
    WifiOutlined,
    CloudDownloadOutlined,
} from '@ant-design/icons';

// Import store
import { OrgsContext } from '../../../../../store/OrgsContext';
import { UserContext } from '../../../../../store/UserContext';
import history from '../../../../../store/history';

// Import components
import GoogleMapView from '../GoogleMapView';
import IconFont from '../../../../../components/IconFont';
import RequestTimer from '../../../../../components/RequestTimer';

// Import utilities
import { connectRequestHub } from '../../../../../utilities/requestHub';

// Import constants
import { OTMActionCodes, SocketIOChannels } from '../../../../../constants/requestHub';

// Import styles
import colors from '../../../../../styles/colors';
import { ORG_ROLES } from '../../../../../constants/orgRoles';

// Import Option, Title, Text and confirm components
const { Option } = Select;
const { Title, Text } = Typography;
const { confirm } = Modal;

const clipInfoColor = {
    backgroundColor: '#595959',
    color: 'white',
    padding: '0 3px',
};

const cropDurationOptions = [
    { value: 20, displayValue: '20s' },
    { value: 30, displayValue: '30s' },
    // { value: 40, displayValue: '40s' },
    // { value: 50, displayValue: '50s' },
    { value: 60, displayValue: '1m' },
    { value: 120, displayValue: '2m' },
    // { value: 180, displayValue: '3m' },
    { value: 300, displayValue: '5m' },
    { value: 600, displayValue: '10m' },
    // { value: 900, displayValue: '15m' },
    // { value: 1800, displayValue: '30m' },
    // { value: 3600, displayValue: '60m' },
];
// Prepare a map to store estimated download time for each crop duration
const estimatedDownloadTime = {
    20: 20,
    30: 30,
    // 40: 25,
    // 50: 35,
    60: 40,
    120: 80,
    // 180: 80,
    300: 190,
    600: 370,
    // 900: 360,
    // 1800: 720,
    // 3600: 1440,
};

// Interval to obtain transfer status and device queue size
export const STREAM_REQUEST_INTERVAL = 2000;

// Prepare a constant for long request notification key
const LONG_REQ_NOTIFICAITON_KEY = 'LONG_REQ';

const MediaPanel = (props) => {
    // Extract values from props
    const {
        data = {},
        vehicleOnFocus = {},
        focusLastPoint,
        focusNextPoint,
        onCancel,
    } = props || {};
    const { unixTime, position } = data || {};
    const {
        rec = '',
        rego = '',
        online = '',
        sdAvailable = false,
        wifiConnected = false,
        sdPICMode = false,
        historyEnabled = false,
        sinceUnix = '',
        tillUnix = '',
        deviceId,
        adminLocked,
        inSleepMode,
    } = vehicleOnFocus || {};

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg, userRole } = orgContext;

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Initialization
    const [isOpen, setIsOpen] = useState(false);
    const [openImgIdx, setOpenImgIdx] = useState(-1);
    const [reqHubSocket, setReqHubSocket] = useState(null);
    const [historyUnixTime, setHistoryUnixTime] = useState(null);
    const [imgLoading, setImgLoading] = useState(false);
    const [channelImgs, setChannelImgs] = useState(Array(8).fill('/imgs/ic_loading.png'));
    const [noDataFound, setNoDataFound] = useState(false);

    const [selectedVideoChannels, setSelectedVideoChannels] = useState([]);
    const [cropDuration, setCropDuration] = useState(cropDurationOptions[0].value);

    const [sendingCropRequest, setSendingCropRequest] = useState(false);

    const [downloadRequests, setDownloadRequests] = useState([]);
    const [downloadStatus, setDownloadStatus] = useState({});
    const [downloadStatusVisible, setDownloadStatusVisible] = useState(false);
    const [hasNewStatus, setHasNewStatus] = useState(false);
    const [deviceQueueSize, setDeviceQueueSize] = useState(0);

    const [_, setRefreshIntervalId] = useState(null);

    const [currentReqId, setCurrentReqId] = useState();

    // Prepare a flag to indicate whether the device is online
    const isDeviceOnline = online === 'online';

    // Prepare data availability based on user's role and device's admin lock status
    const isDataAvailable = !adminLocked || userRole === ORG_ROLES.ADMIN.name;

    const historyTimeWithTZ = moment(historyUnixTime).tz(timezone);

    useEffect(() => () => notification.close(LONG_REQ_NOTIFICAITON_KEY), []);

    useEffect(() => {
        // Establish a websocket connection to request hub
        const connect = async () => {
            const socketIO = await connectRequestHub(selectedOrg);
            setReqHubSocket(socketIO);
        };

        // Connect to request hub if not connected
        if (!reqHubSocket) connect();

        return () => {
            // Disconnect from request hub if connected
            if (reqHubSocket) {
                reqHubSocket.disconnect();
                setReqHubSocket(null);
            }
        };
    }, [reqHubSocket]);

    useEffect(() => {
        // Request to obtain organisation transfer status and device queue size
        const getTransferStatus = () => {
            reqHubSocket.emit(
                SocketIOChannels.TRANSFER_STATUS,
                { requestId: uuid4(), deviceId },
                (res) => {
                    const { orgTransferStatus = {}, deviceQueueSize = 0 } = res || {};
                    setDownloadStatus((current) => {
                        if (
                            !isEqual(current, orgTransferStatus) &&
                            Object.keys(current).length !== 0
                        ) {
                            setHasNewStatus(true);
                        }
                        return orgTransferStatus;
                    });
                    setDeviceQueueSize(deviceQueueSize);
                }
            );
        };

        if (!isDeviceOnline || !isDataAvailable) {
            setChannelImgs(Array(8).fill('/imgs/ic_nosignal.png'));
        } else if (reqHubSocket) {
            getTransferStatus();
            const interval = setInterval(() => getTransferStatus(), STREAM_REQUEST_INTERVAL);
            setRefreshIntervalId(interval);
        }

        return () => {
            setRefreshIntervalId((current) => {
                clearInterval(current);
                return null;
            });
        };
    }, [reqHubSocket, isDeviceOnline, isDataAvailable]);

    useEffect(() => {
        const updateChannelImgs = (newUnixTime) => {
            setImgLoading(true);
            let newChannelImgs = Array(8).fill('/imgs/ic_loading.png');

            // Prepare history image data
            if (historyEnabled && sdPICMode && sdAvailable && rec) {
                // Obtain history image from device
                const requestId = uuid4();
                const ts = Math.floor(newUnixTime / 1000);
                reqHubSocket.emit(SocketIOChannels.REQUEST, {
                    deviceId,
                    actionCode: OTMActionCodes.OBTAIN_HISTORY_IMAGES,
                    requestId: requestId,
                    s: ts,
                    e: ts,
                    chs: [1, 2, 3, 4, 5, 6, 7, 8],
                });
                setCurrentReqId(requestId);
                notification.close(LONG_REQ_NOTIFICAITON_KEY);

                reqHubSocket.on(
                    `${SocketIOChannels.REQUEST}:${requestId}`,
                    async ({ statusCode, data }) => {
                        setNoDataFound(statusCode === 5404);
                        newChannelImgs = [];
                        for (let idx = 0; idx < 8; idx += 1) {
                            const base64Data = data?.[0]?.[`ch${idx + 1}`];
                            if (!inSleepMode && rec.indexOf((idx + 1).toString()) === -1) {
                                newChannelImgs.push('/imgs/ic_channeloff.png');
                            } else if (base64Data) {
                                newChannelImgs.push(`data:image/png;base64, ${base64Data}`);
                            } else newChannelImgs.push('/imgs/ic_nodata.png');
                        }
                        setChannelImgs(newChannelImgs);
                        setImgLoading(false);

                        setCurrentReqId(undefined);
                        notification.close(LONG_REQ_NOTIFICAITON_KEY);
                    }
                );
            } else {
                newChannelImgs = Array(8).fill(
                    !sdAvailable ? '/imgs/ic_noSDCard.png' : '/imgs/ic_nosignal.png'
                );
                setImgLoading(false);
            }

            setChannelImgs(newChannelImgs);
        };

        if (reqHubSocket && isDeviceOnline && historyUnixTime) updateChannelImgs(historyUnixTime);
    }, [reqHubSocket, isDeviceOnline, historyUnixTime]);

    useEffect(() => {
        if (unixTime) setHistoryUnixTime(unixTime);
    }, [unixTime]);

    const cropVideo = async () => {
        try {
            setSendingCropRequest(true);

            // Request a video from device
            const requestId = uuid4();
            const requestData = {
                deviceId,
                actionCode: OTMActionCodes.REQUEST_VIDEO,
                requestId: requestId,
                d: {
                    chs: selectedVideoChannels,
                    s: historyTimeWithTZ.clone().subtract(10, 'second').unix(),
                    e: historyTimeWithTZ
                        .clone()
                        .subtract(10, 'second')
                        .add(cropDuration, 'second')
                        .unix(),
                    w: false,
                },
            };
            reqHubSocket.emit(SocketIOChannels.REQUEST, requestData, ({ success }) => {
                if (!isDeviceOnline) {
                    if (success) {
                        Modal.success({
                            content:
                                'A video download job has been scheduled. The job will be executed once the device is online. You can check the status on the Download page.',
                            centered: true,
                        });
                    } else {
                        Modal.error({
                            content:
                                'Unable to schedule a video download job. Please try again later.',
                            centered: true,
                        });
                    }

                    setSendingCropRequest(false);
                }
            });

            reqHubSocket.on(`${SocketIOChannels.REQUEST}:${requestId}`, (data) => {
                setSendingCropRequest(false);

                // Handle errors
                if (!data?.downloadIds || !data?.downloadRequestId || data?.statusCode === 5404) {
                    Modal.error({
                        content: 'No footage found, please choose another date and time.',
                        centered: true,
                    });
                } else {
                    setDownloadRequests((reqs) => [
                        {
                            requestId: data?.downloadRequestId ?? '',
                            downloadIds: data?.downloadIds ?? {},
                            queryStartTime: requestData.d.s,
                            queryEndTime: requestData.d.e,
                            cropDuration,
                        },
                        ...reqs,
                    ]);
                    setDownloadStatusVisible(true);
                }
            });
        } catch (error) {
            Modal.error({
                content:
                    'Video file is not found or has been overwritten, please choose another time.',
                centered: true,
            });
        }
    };

    const moveToLastSec = () => {
        let updated = false;
        const prevSec = historyTimeWithTZ.clone().subtract(1, 'second');
        if (prevSec.isSameOrBefore(moment(unixTime).subtract(10, 'seconds'))) {
            updated = focusLastPoint(unixTime, prevSec.valueOf());
        }

        if (!updated) {
            setHistoryUnixTime((currentTime) =>
                moment(currentTime).subtract(1, 'second').valueOf()
            );
        }
    };

    const moveToNextSec = () => {
        let updated = false;
        const nextSec = historyTimeWithTZ.clone().add(1, 'second');
        if (nextSec.isSameOrAfter(moment(unixTime).add(10, 'seconds'))) {
            updated = focusNextPoint(unixTime, nextSec.valueOf());
        }

        if (!updated) {
            setHistoryUnixTime((currentTime) => moment(currentTime).add(1, 'second').valueOf());
        }
    };

    // Popover download status
    const StatusPopover = () => {
        const getStatusDisplay = (downloadReq, downloadId) => {
            // Extract values
            const { cropDuration } = downloadReq;
            const { transferStatus, transferProgress, transferStatusCode } =
                downloadStatus?.[downloadId] || {};

            if (transferStatus === 'FAILED') {
                let errorMsg = 'Unknown error';
                switch (transferStatusCode) {
                    case 5400:
                        errorMsg = 'Transfer error';
                        break;
                    case 5404:
                        errorMsg = 'No footage found';
                        break;
                    case 5410:
                        errorMsg = 'Request expired';
                        break;
                    default:
                        break;
                }

                return <Badge status="error" text={`Failed: ${errorMsg}`} />;
            }

            if (transferStatus === 'COMPLETED') {
                return (
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                            message.info('Preparing ...');
                            reqHubSocket.emit(
                                SocketIOChannels.DOWNLOAD_URL,
                                { downloadId },
                                async ({ downloadUrl, errorMessage }) => {
                                    if (downloadUrl) {
                                        message.success('Downloading ...');
                                        const matches = downloadUrl.match(/\/([^\/]+\.avi)\?/);
                                        const filename =
                                            matches?.[1] ?? `${downloadReq.queryStartTime}.avi`;
                                        await saveAs(downloadUrl, decodeURIComponent(filename));
                                    } else if (errorMessage) {
                                        message.error(errorMessage);
                                    }
                                }
                            );
                        }}
                    >
                        Download
                    </Button>
                );
            }

            if (!transferProgress) {
                // Initialisation
                let estimatedDurationText = 'unknown';

                // Extract estimated duration
                const estimatedDuration = estimatedDownloadTime?.[cropDuration];

                if (estimatedDuration < 60) {
                    estimatedDurationText = `${estimatedDuration} sec`;
                } else {
                    const parsedEstimatedDuration = moment.duration(estimatedDuration, 'seconds');
                    if (parsedEstimatedDuration.seconds() === 0) {
                        estimatedDurationText = `${parsedEstimatedDuration.minutes()} min`;
                    } else {
                        estimatedDurationText = `${parsedEstimatedDuration.minutes()} min ${parsedEstimatedDuration.seconds()} sec`;
                    }
                }
                return (
                    <Text type="secondary">Pending (Estimated&nbsp;{estimatedDurationText})</Text>
                );
            } else {
                return (
                    <Progress
                        percent={downloadStatus?.[downloadId]?.transferProgress ?? 0}
                        style={{ width: '60%' }}
                    />
                );
            }
        };

        return (
            <Timeline mode="left">
                {downloadRequests.map((downloadReq) => (
                    <Timeline.Item key={downloadReq.requestId}>
                        <Space>
                            <Text strong>
                                {moment(downloadReq.queryStartTime * 1000).format('HH:mm:ss')}
                            </Text>
                            <Text type="secondary">-</Text>

                            <Text strong>
                                {moment(downloadReq.queryEndTime * 1000).format('HH:mm:ss')}
                            </Text>
                        </Space>

                        <Divider style={{ margin: '6px 0' }} />

                        <Space direction="vertical" style={{ width: '100%' }}>
                            {Object.entries(downloadReq.downloadIds).map(
                                ([channel, downloadId]) => (
                                    <div
                                        key={downloadId}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            paddingLeft: '15px',
                                        }}
                                    >
                                        <Text style={{ width: '18%', marginRight: '5px' }}>
                                            CH{channel}:
                                        </Text>
                                        {getStatusDisplay(downloadReq, downloadId)}
                                    </div>
                                )
                            )}
                        </Space>
                    </Timeline.Item>
                ))}
            </Timeline>
        );
    };

    return (
        <Modal
            title={
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '30px',
                    }}
                >
                    <Title level={4} style={{ marginBottom: 0, fontSize: '16px' }}>
                        History Media
                    </Title>

                    <Space split={<Divider type="vertical" />}>
                        {downloadRequests.length !== 0 && (
                            <Popover
                                title={
                                    <div style={{ textAlign: 'right' }}>
                                        {`Device processing: ${deviceQueueSize} ${
                                            deviceQueueSize > 1 ? 'jobs' : 'job'
                                        }`}
                                    </div>
                                }
                                content={<StatusPopover />}
                                trigger="click"
                                placement="bottomLeft"
                                open={downloadStatusVisible}
                                onOpenChange={(openStatus) => {
                                    setDownloadStatusVisible(openStatus);
                                    setHasNewStatus(false);
                                }}
                                overlayStyle={{ width: '400px' }}
                                overlayInnerStyle={{ padding: '10px 10px 0px 10px' }}
                            >
                                <Badge dot={!downloadStatusVisible && hasNewStatus}>
                                    <Button
                                        icon={<DownloadOutlined />}
                                        onClick={() => setDownloadStatusVisible(true)}
                                        disabled={downloadRequests.length === 0}
                                    >
                                        Downloads
                                    </Button>
                                </Badge>
                            </Popover>
                        )}

                        <Text type="secondary" css={{ fontSize: '16px', lineHeight: '1.4' }}>
                            {historyUnixTime && historyTimeWithTZ.format('MMMM Do YYYY, h:mm:ss a')}
                        </Text>
                    </Space>
                </div>
            }
            open
            footer={null}
            width="1350px"
            onCancel={() => {
                // Check if there are any incomplete downloads
                const downloadIds = downloadRequests.reduce(
                    (prev, current) => [...prev, ...Object.values(current.downloadIds)],
                    []
                );
                const hasIncompleteDownloads = downloadIds.some((downloadId) => {
                    const { transferStatus } = downloadStatus?.[downloadId] || {};
                    return (
                        !transferStatus ||
                        ['PENDING', 'IN_PROGRESS', 'RETRYING'].includes(transferStatus)
                    );
                });

                if (!hasIncompleteDownloads) onCancel();
                else {
                    confirm({
                        title: 'Do you want to quit?',
                        icon: <ExclamationCircleOutlined />,
                        content:
                            'You can always view your downloads on the download requests page.',
                        onOk: onCancel,
                    });
                }
            }}
        >
            <div css={{ display: 'flex' }}>
                <div css={{ width: '55%', marginRight: '30px' }}>
                    <div css={{ margin: '0px auto 20px auto', textAlign: 'center' }}>
                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '20px',
                            }}
                        >
                            <Title level={4}>
                                <span style={{ fontWeight: 300 }}>Fleet ID:</span>{' '}
                                {rego || 'Unknown'}
                            </Title>
                            <Space>
                                <Text>SD Card</Text>
                                <IconFont
                                    type="iconic_sd"
                                    css={{ color: sdAvailable ? 'green' : 'red', fontSize: '25px' }}
                                />
                                <Text>{isDeviceOnline ? 'Online' : 'Offline'}</Text>
                                {isDeviceOnline && wifiConnected ? (
                                    <WifiOutlined style={{ color: 'green', fontSize: '25px' }} />
                                ) : (
                                    <IconFont
                                        type="iconic_network"
                                        css={{
                                            color: isDeviceOnline ? 'green' : 'red',
                                            fontSize: '25px',
                                        }}
                                    />
                                )}
                            </Space>
                        </div>

                        <Descriptions column={4} layout="vertical" colon={false}>
                            {channelImgs.map((imgSrc, idx) => (
                                <Descriptions.Item
                                    label={
                                        <Space size={50}>
                                            <Text strong>{`Channel ${idx + 1}`}</Text>
                                            {[
                                                '/imgs/ic_noSDCard.png',
                                                '/imgs/ic_nosignal.png',
                                                '/imgs/ic_channeloff.png',
                                                '/imgs/ic_loading.png',
                                            ].indexOf(imgSrc) < 0 && (
                                                <Tooltip title="Download image">
                                                    <Button
                                                        icon={<DownloadOutlined />}
                                                        size="small"
                                                        onClick={() =>
                                                            saveAs(imgSrc, 'image.jpg', 'image/*')
                                                        }
                                                        style={{ width: '30px' }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Space>
                                    }
                                    key={`channel-${idx}`}
                                >
                                    <Spin spinning={imgLoading}>
                                        {/* eslint-disable-next-line */}
                                        <img
                                            src={imgSrc}
                                            alt={`channel_${idx}`}
                                            css={{
                                                width: '150px',
                                                height: '150px',
                                                border: '0',
                                                backgroundColor: '#F8F7F7',
                                            }}
                                            onClick={() => {
                                                if (
                                                    [
                                                        '/imgs/ic_noSDCard.png',
                                                        '/imgs/ic_nosignal.png',
                                                        '/imgs/ic_loading.png',
                                                    ].indexOf(imgSrc) < 0
                                                ) {
                                                    setIsOpen(true);
                                                    setOpenImgIdx(idx);
                                                } else {
                                                    message.info('No channel image found');
                                                }
                                            }}
                                            onError={(ev) => {
                                                ev.target.src = '/imgs/ic_nosignal.png';
                                            }}
                                        />
                                    </Spin>
                                </Descriptions.Item>
                            ))}
                        </Descriptions>

                        <div
                            css={{
                                margin: '20px auto',
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <div
                                css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: colors.primary,
                                    padding: '1px',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        focusLastPoint(unixTime);
                                    }}
                                    disabled={
                                        historyTimeWithTZ
                                            .clone()
                                            .subtract(10, 'second')
                                            .isBefore(moment(sinceUnix)) ||
                                        sendingCropRequest ||
                                        !isDataAvailable
                                    }
                                    css={{ border: 0, padding: '4px 10px' }}
                                    ghost
                                >
                                    <IconFont type="iconic_last02" />
                                    Last data point
                                </Button>
                                <Divider type="vertical" />
                                <Button
                                    onClick={moveToLastSec}
                                    disabled={
                                        historyTimeWithTZ
                                            .clone()
                                            .subtract(1, 'second')
                                            .isBefore(moment(sinceUnix)) ||
                                        sendingCropRequest ||
                                        !isDataAvailable
                                    }
                                    css={{ border: 0, padding: '4px 10px' }}
                                    ghost
                                >
                                    <IconFont type="iconic_last" />
                                    Last second
                                </Button>
                            </div>
                            <div
                                css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: colors.primary,
                                    padding: '1px',
                                }}
                            >
                                <Button
                                    onClick={moveToNextSec}
                                    disabled={
                                        historyTimeWithTZ
                                            .clone()
                                            .add(1, 'second')
                                            .isAfter(moment(tillUnix)) ||
                                        sendingCropRequest ||
                                        !isDataAvailable
                                    }
                                    css={{ border: 0, padding: '4px 10px' }}
                                    ghost
                                >
                                    Next second
                                    <IconFont type="iconic_next" />
                                </Button>
                                <Divider type="vertical" />
                                <Button
                                    onClick={() => {
                                        focusNextPoint(unixTime);
                                    }}
                                    disabled={
                                        historyTimeWithTZ
                                            .clone()
                                            .add(10, 'second')
                                            .isAfter(moment(tillUnix)) ||
                                        sendingCropRequest ||
                                        !isDataAvailable
                                    }
                                    css={{ border: 0, padding: '4px 10px' }}
                                    ghost
                                >
                                    Next data point
                                    <IconFont type="iconic_next02" />
                                </Button>
                            </div>
                        </div>
                    </div>

                    {(!sdAvailable || noDataFound) && (
                        <Alert
                            message="Channel previews are unavailable at this time. The SD card may be damaged or formatted. However, you may still request videos below."
                            type="warning"
                            showIcon
                        />
                    )}

                    {![ORG_ROLES.VIEWER.name, ORG_ROLES.ARCHIVIST.name].includes(userRole) && (
                        <div css={{ margin: '30px auto 0 auto' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: '30px 0px',
                                }}
                            >
                                <Button
                                    onClick={() => history.push('/downloads')}
                                    icon={<FieldTimeOutlined />}
                                >
                                    All Request History
                                </Button>

                                <Alert
                                    message={
                                        <Space align="center">
                                            <span>Footage Range:</span>
                                            <span css={clipInfoColor}>
                                                {historyTimeWithTZ
                                                    .clone()
                                                    .subtract(10, 'second')
                                                    .format('HH:mm:ss')}
                                            </span>
                                            <span>-</span>
                                            <span css={clipInfoColor}>
                                                {historyTimeWithTZ
                                                    .clone()
                                                    .subtract(10, 'second')
                                                    .add(cropDuration, 'second')
                                                    .format('HH:mm:ss')}
                                            </span>
                                        </Space>
                                    }
                                    type="info"
                                    css={{
                                        backgroundColor: '#f0f0f0',
                                        borderColor: '#f0f0f0',
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        borderRadius: '5px',
                                        '.ant-alert-icon': { color: '#595959', top: '8px' },
                                    }}
                                />
                            </div>

                            <div
                                css={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Space size="large">
                                    <Space>
                                        <span>Channels:</span>
                                        <Select
                                            mode="multiple"
                                            value={selectedVideoChannels}
                                            onChange={(checkedValues) => {
                                                setSelectedVideoChannels(checkedValues);
                                            }}
                                            style={{ width: '200px' }}
                                            placeholder="Select channels"
                                            maxTagCount="responsive"
                                            optionLabelProp="label"
                                            disabled={sendingCropRequest}
                                        >
                                            {Array.from({ length: 8 }, (_, i) => i + 1).map(
                                                (channel) => (
                                                    <Option
                                                        key={channel}
                                                        value={channel}
                                                        label={`CH${channel}`}
                                                    >
                                                        Channel {channel}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </Space>

                                    <Space>
                                        <span>Duration:</span>
                                        <Select
                                            value={cropDuration}
                                            onChange={(value) => setCropDuration(value)}
                                            css={{
                                                '.ant-select-selector': {
                                                    paddingLeft: '3px!important',
                                                    height: '30px!important',
                                                },
                                                '.ant-select-selection-item': {
                                                    lineHeight: '2!important',
                                                },
                                            }}
                                            dropdownMatchSelectWidth={100}
                                            style={{ width: '100px' }}
                                        >
                                            {cropDurationOptions.map((duration) => (
                                                <Option value={duration.value} key={duration.value}>
                                                    {duration.displayValue}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Space>
                                </Space>

                                <Button
                                    type="primary"
                                    icon={<CloudDownloadOutlined />}
                                    onClick={cropVideo}
                                    loading={sendingCropRequest}
                                    disabled={
                                        !isDataAvailable || selectedVideoChannels.length === 0
                                    }
                                >
                                    {isDeviceOnline ? 'Download Video' : 'Schedule Video'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                <div css={{ width: '40%' }}>
                    {position && position[0] && position[1] ? (
                        <GoogleMapView lat={position[0]} lng={position[1]} />
                    ) : (
                        <div>Not found</div>
                    )}
                </div>
            </div>

            {isOpen && openImgIdx >= 0 && (
                <div>
                    <Lightbox
                        mainSrc={channelImgs[openImgIdx]}
                        prevSrc={
                            historyTimeWithTZ
                                .clone()
                                .subtract(1, 'second')
                                .isBefore(moment(sinceUnix))
                                ? undefined
                                : channelImgs[openImgIdx]
                        }
                        nextSrc={
                            historyTimeWithTZ.clone().add(1, 'second').isAfter(moment(tillUnix))
                                ? undefined
                                : channelImgs[openImgIdx]
                        }
                        onCloseRequest={() => {
                            setIsOpen(false);
                            setOpenImgIdx(-1);
                        }}
                        onImageLoadError={() => {
                            setIsOpen(false);
                            setOpenImgIdx(-1);
                        }}
                        enableZoom={false}
                        onMovePrevRequest={
                            !sendingCropRequest
                                ? moveToLastSec
                                : () => message.warning('Download in progress')
                        }
                        onMoveNextRequest={
                            !sendingCropRequest
                                ? moveToNextSec
                                : () => message.warning('Download in progress')
                        }
                    />
                </div>
            )}

            {/* Add a request timer */}
            <RequestTimer
                requestId={currentReqId}
                notifications={{
                    10: () =>
                        notification.info({
                            key: LONG_REQ_NOTIFICAITON_KEY,
                            placement: 'topLeft',
                            duration: 0,
                            message: 'Request in progress',
                            description:
                                'Your request is still in progress. Please note, network instability may cause occasional delays.',
                        }),
                }}
            />
        </Modal>
    );
};

export default MediaPanel;
