/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import Ant Design components
import { Layout, Typography } from 'antd';

// Import stylesheets
import styles from './styles';

// Import Footer component
const { Text, Link } = Typography;
const { Footer } = Layout;

const AppFooter = (props) => {
    return (
        <Footer css={styles.footer}>
            {/* Links */}
            <div css={styles.contact}>
                <Text type="secondary">
                    Technical Enquiries: support@fleetway.com.au &nbsp; Telephone: (07) 4276 6666
                </Text>
            </div>

            {/* Copyright */}
            {/* eslint-disable-next-line */}
            <div css={styles.about}>
                &copy; Fleetway, 2015-2024 &#10084;&#65039; &nbsp; Brisbane-based &amp; Australian
                made | ABN: 20 606 736 047
            </div>

            <div css={styles.footerLinks}>
                <Link href="/files/tcs.pdf" target="_blank">
                    Terms and Conditions
                </Link>
                {/* 
                <Link to="#">Privacy policies</Link>
                <Link to="#">Security</Link> */}
            </div>
        </Footer>
    );
};

export default AppFooter;
