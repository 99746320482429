/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import uuid4 from 'uuid/v4';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { useState, useCallback, useEffect } from 'react';

// Import Ant Design components
import {
    Empty,
    Timeline,
    Badge,
    Button,
    Progress,
    Space,
    Typography,
    Divider,
    Card,
    message,
} from 'antd';

// Import constants
import { SocketIOChannels } from '../../../../constants/requestHub';

// Import store
import history from '../../../../store/history';

// Import additional Ant Design components
const { Text } = Typography;

// Interval to obtain transfer status and device queue size
export const STREAM_REQUEST_INTERVAL = 2000;

const Downloads = ({ vehicle, reqHubSocket, isReady, downloadRequests }) => {
    // Extract values from vehicle
    const { deviceId } = vehicle ?? {};

    // Initialisation
    const [downloadStatus, setDownloadStatus] = useState({});
    const [deviceQueueSize, setDeviceQueueSize] = useState(0);
    const [_, setRefreshIntervalId] = useState(null);

    const getTransferStatus = useCallback(() => {
        if (reqHubSocket && deviceId) {
            reqHubSocket.emit(
                SocketIOChannels.TRANSFER_STATUS,
                { requestId: uuid4(), deviceId },
                (res) => {
                    const { orgTransferStatus = {}, deviceQueueSize = 0 } = res || {};
                    setDownloadStatus(orgTransferStatus);
                    setDeviceQueueSize(deviceQueueSize);
                }
            );
        }
    }, [reqHubSocket, deviceId]);

    useEffect(() => {
        getTransferStatus();
        const interval = setInterval(() => getTransferStatus(), STREAM_REQUEST_INTERVAL);
        setRefreshIntervalId(interval);

        return () => {
            setRefreshIntervalId((current) => {
                clearInterval(current);
                return null;
            });
        };
    }, [getTransferStatus]);

    const getStatusDisplay = (downloadReq, downloadId) => {
        // Extract values
        const { transferStatus, transferProgress, transferStatusCode } =
            downloadStatus?.[downloadId] || {};

        if (transferStatus === 'FAILED') {
            let errorMsg = 'Unknown error';
            switch (transferStatusCode) {
                case 5400:
                    errorMsg = 'Transfer error';
                    break;
                case 5404:
                    errorMsg = 'No footage found';
                    break;
                case 5410:
                    errorMsg = 'Request expired';
                    break;
                default:
                    break;
            }

            return <Badge status="error" text={`Failed: ${errorMsg}`} />;
        }

        if (transferStatus === 'COMPLETED') {
            return (
                <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                        message.info('Preparing ...');
                        reqHubSocket.emit(
                            SocketIOChannels.DOWNLOAD_URL,
                            { downloadId },
                            async ({ downloadUrl, errorMessage }) => {
                                if (downloadUrl) {
                                    message.success('Downloading ...');
                                    const matches = downloadUrl.match(/\/([^\/]+\.avi)\?/);
                                    const filename =
                                        matches?.[1] ?? `${downloadReq.queryStartTime}.avi`;
                                    await saveAs(downloadUrl, decodeURIComponent(filename));
                                } else if (errorMessage) {
                                    message.error(errorMessage);
                                }
                            }
                        );
                    }}
                >
                    Download
                </Button>
            );
        }

        if (!transferProgress) {
            return <Text type="secondary">Pending</Text>;
        } else {
            return (
                <Progress
                    percent={downloadStatus?.[downloadId]?.transferProgress ?? 0}
                    style={{ width: '60%' }}
                />
            );
        }
    };

    return (
        <Card
            title="Current Downloads"
            loading={!isReady}
            style={{
                width: '28%',
                minHeight: '500px',
                maxHeight: '800px',
                overflowY: 'auto',
            }}
            extra={<Button onClick={() => history.push('/downloads')}>All Downloads</Button>}
            // extra={rego ? `Processing ${deviceQueueSize} jobs` : undefined}
        >
            {downloadRequests.length === 0 ? (
                <Empty description="Your downloads will appear here" css={{ marginTop: '50px' }} />
            ) : (
                <Timeline mode="left">
                    {downloadRequests.map((downloadReq) => (
                        <Timeline.Item key={downloadReq.requestId}>
                            <Space>
                                <Text strong>
                                    {moment(downloadReq.queryStartTime * 1000).format('HH:mm:ss')}
                                </Text>
                                <Text type="secondary">-</Text>
                                <Text strong>
                                    {moment(downloadReq.queryEndTime * 1000).format('HH:mm:ss')}
                                </Text>
                            </Space>

                            <Divider style={{ margin: '6px 0' }} />

                            <Space direction="vertical" style={{ width: '100%' }}>
                                {Object.entries(downloadReq.downloadIds).map(
                                    ([channel, downloadId]) => (
                                        <div
                                            key={downloadId}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                paddingLeft: '15px',
                                            }}
                                        >
                                            <Text style={{ width: '18%', marginRight: '5px' }}>
                                                CH{channel}:
                                            </Text>
                                            {getStatusDisplay(downloadReq, downloadId)}
                                        </div>
                                    )
                                )}
                            </Space>
                        </Timeline.Item>
                    ))}
                </Timeline>
            )}
        </Card>
    );
};

export default Downloads;
