/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import moment from 'moment';
import { useState } from 'react';
import MapboxClient from 'mapbox';

// Import Ant Design component
import {
    Drawer,
    Button,
    DatePicker,
    Radio,
    AutoComplete,
    Select,
    List,
    Badge,
    Checkbox,
    Typography,
    message,
} from 'antd';
import { MenuUnfoldOutlined, SearchOutlined, FieldTimeOutlined } from '@ant-design/icons';

// Import stores
import history from '../../../../../store/history';

// Import additional Ant Design components
const { Option } = Select;
const { Paragraph } = Typography;

// Define constants
const SEARCH_DELAY_INTERVAL = 500;
const MAX_SEARCH_BY_REGO = 8;

const ControlPanel = (props) => {
    const {
        vehicles,
        cleanUp,
        selectedVehicleRegos,
        setSelectedVehicleRegos,
        drawerVisible,
        orgVehicles,
        handleDrawerClose,
        handleHistorySearch,
        handleSearchModeChange,
        eventViewOptions,
        onViewSettingsChanged,
    } = props;

    const [searchDate, setSearchDate] = useState(moment());
    const [searchType, setSearchType] = useState('location');
    const [searchGeoLoc, setSearchGeoLoc] = useState([-27.46977, 153.025131]);
    const [searchRange, setSearchRange] = useState(200);
    const [searchVehicles, setSearchVehicles] = useState([]);
    const [timer, setTimer] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddr, setSelectedAddr] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);

    // Initialize a new Mapbox client
    const mapboxClient = new MapboxClient(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);

    const handleAddressSearch = (userInputData) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                // Handle Mapbox Geocoding Search here
                if (userInputData.length > 3) {
                    // Get geolocations
                    mapboxClient.geocodeForward(userInputData, { country: 'AU' }, (err, res) => {
                        if (res) {
                            const source = [];
                            res.features.forEach((feature) =>
                                source.push({
                                    coordinates: feature.geometry.coordinates,
                                    value: feature.place_name.toString(),
                                })
                            );
                            setAddresses(source);
                        }
                    });
                }
            }, SEARCH_DELAY_INTERVAL)
        );
    };

    const onAddressSelect = (value, option) => {
        const { coordinates } = option || {};
        const [longitude, latitude] = coordinates;
        setSearchGeoLoc([latitude, longitude]);
        setSelectedAddr(value);
    };

    const onHistorySearch = async () => {
        setSearchLoading(true);
        try {
            await handleHistorySearch(
                searchDate,
                searchType,
                searchGeoLoc,
                searchRange,
                searchVehicles
            );
            setSearchLoading(false);
        } catch (error) {
            console.log(error);
            message.error('Unable to fetch history data');
            setSearchLoading(false);
        }
    };

    return (
        <Drawer
            title={
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        icon={<FieldTimeOutlined />}
                        onClick={() => history.push('/history/time')}
                    >
                        Time View
                    </Button>
                    <Button
                        size="small"
                        icon={<MenuUnfoldOutlined />}
                        onClick={handleDrawerClose}
                    />
                </div>
            }
            placement="right"
            width="350px"
            mask={false}
            getContainer={false}
            closable={false}
            open={drawerVisible}
        >
            {/* Search Query Date */}
            <h3>Select Date</h3>
            <DatePicker
                defaultValue={searchDate}
                onChange={(date) => setSearchDate(date)}
                style={{ width: '90%', marginTop: '10px' }}
                format="DD/MM/YYYY"
            />

            {/* Search Query Type */}
            <Radio.Group
                onChange={(e) => {
                    setSearchType(e.target.value);
                    handleSearchModeChange();
                    setSelectedAddr('');
                    setAddresses([]);
                    setSearchVehicles([]);
                }}
                value={searchType}
                css={{ margin: '30px 0px' }}
            >
                <Radio value="location">By Location</Radio>
                <Radio value="rego">By Rego</Radio>
            </Radio.Group>

            {/* Search Location or Rego */}
            <div>
                {searchType === 'location' ? (
                    <div>
                        <h3>Search by Address</h3>
                        <AutoComplete
                            options={addresses}
                            css={{ width: '90%', marginTop: '10px' }}
                            onSelect={onAddressSelect}
                            onSearch={handleAddressSearch}
                            placeholder="Enter an Address"
                            allowClear
                            value={selectedAddr}
                            onChange={(value) => {
                                setSelectedAddr(value);
                                cleanUp();
                            }}
                        />
                        <Select
                            value={searchRange}
                            css={{ width: '90%', marginTop: '10px' }}
                            onChange={(value) => {
                                setSearchRange(value);
                                cleanUp();
                            }}
                        >
                            <Option value={100}>100m</Option>
                            <Option value={200}>200m</Option>
                            <Option value={300}>300m</Option>
                            <Option value={400}>400m</Option>
                        </Select>
                    </div>
                ) : (
                    <div>
                        <h3>Search by Rego</h3>
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            placeholder="Enter vehicle rego"
                            options={orgVehicles}
                            value={searchVehicles}
                            onChange={(values) => {
                                if (values.length <= MAX_SEARCH_BY_REGO) {
                                    setSearchVehicles(values);
                                    cleanUp();
                                } else {
                                    message.warning(
                                        `You can only select up to ${MAX_SEARCH_BY_REGO} vehicles.`
                                    );
                                }
                            }}
                            maxTagCount={MAX_SEARCH_BY_REGO}
                            css={{ width: '90%', marginTop: '10px' }}
                        />
                    </div>
                )}
            </div>

            {/* Actions */}
            <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={onHistorySearch}
                loading={searchLoading}
                css={{ margin: '30px 0px' }}
            >
                Search
            </Button>

            {searchType === 'rego' && searchLoading && searchVehicles.length > 1 ? (
                <Paragraph type="secondary">
                    Searching across multiple vehicles may take additional time.
                </Paragraph>
            ) : null}

            {/* Search Results */}
            {vehicles && (
                <div>
                    <h3>Search Results</h3>
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        value={selectedVehicleRegos}
                        onChange={(values) => setSelectedVehicleRegos(values)}
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={vehicles}
                            pagination={{ size: 'small', pageSize: MAX_SEARCH_BY_REGO }}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.rego}
                                    onClick={() => {
                                        if (searchType === 'location') {
                                            setSelectedVehicleRegos([item.rego]);
                                        }
                                    }}
                                    css={{ display: 'flex' }}
                                >
                                    <List.Item.Meta
                                        title={
                                            searchType === 'rego' ? (
                                                <Checkbox value={item.rego}>
                                                    <Badge
                                                        text={item.rego || 'N/A'}
                                                        style={{ color: item.colorHex }}
                                                        color={item.groupColor || 'gray'}
                                                    />
                                                </Checkbox>
                                            ) : (
                                                <Badge
                                                    text={item.rego || 'N/A'}
                                                    style={{ color: item.colorHex }}
                                                    color={item.groupColor || 'gray'}
                                                />
                                            )
                                        }
                                        css={{ '.ant-list-item-meta-title': { marginBottom: 0 } }}
                                    />
                                    {item.since} - {item.till}
                                </List.Item>
                            )}
                        />
                    </Checkbox.Group>
                </div>
            )}

            {/* View settings */}
            {eventViewOptions.length > 0 && (
                <div>
                    <h3>Display Events</h3>
                    <Checkbox.Group
                        options={eventViewOptions.filter((option) => option.value !== '99')}
                        onChange={onViewSettingsChanged}
                        css={{ '.ant-checkbox-group-item': { margin: '5px 8px 5px 0px' } }}
                    />
                </div>
            )}
        </Drawer>
    );
};

export default ControlPanel;
