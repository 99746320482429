/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import TimeRange from 'react-video-timelines-slider';

// Import store
import { UserContext } from '../../../../store/UserContext';

// Import Ant Design components
import {
    Typography,
    Button,
    Row,
    Col,
    Spin,
    Descriptions,
    TimePicker,
    Space,
    Tooltip,
    Select,
} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

// Import styles
import styles from './styles';

// Import additional Ant Design components
const { Text, Title } = Typography;

// Prepare constants
const DEFAULT_DURATION = 10 * 60 * 1000;
const MAX_DURATION = 10 * 60 * 1000;

const Channel = ({
    name,
    data,
    preview,
    loadingImage,
    requestingVideo,
    handleRequestVideo,
    channels,
    selectedChannel,
    onChannelChange,
}) => {
    // Initialisation
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [selectedStartTime, setSelectedStartTime] = useState();
    const [selectedEndTime, setSelectedEndTime] = useState();

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    useEffect(() => {
        if (data && data.length > 0) {
            setStartTime(new Date(data[0][0]));
            setEndTime(new Date(data[data.length - 1][1]));
            setSelectedStartTime(new Date(data[0][0]));
            setSelectedEndTime(
                data[0][0] + DEFAULT_DURATION <= data[data.length - 1][1]
                    ? new Date(data[0][0] + DEFAULT_DURATION)
                    : new Date(data[data.length - 1][1])
            );
        }
    }, [data]);

    // Compute disabled intervals which are gaps between each end and start
    const gaps = useMemo(() => {
        const disabledIntervals = [];

        for (let i = 0; i < data.length - 1; i++) {
            disabledIntervals.push({ start: new Date(data[i][1]), end: new Date(data[i + 1][0]) });
        }

        return disabledIntervals;
    }, [data]);

    const duration = useMemo(() => {
        if (!selectedStartTime || !selectedEndTime) return 0;

        return moment(selectedEndTime).diff(selectedStartTime, 'seconds');
    }, [selectedStartTime, selectedEndTime]);

    const durationAllowed = useMemo(() => {
        return duration * 1000 <= MAX_DURATION && duration > 0;
    }, [duration]);

    const durationDisplay = useMemo(() => {
        if (duration > 60) {
            if (duration % 60 === 0) {
                return `${Math.floor(duration / 60)} minutes`;
            }
            return `${Math.floor(duration / 60)} minutes ${duration % 60} seconds`;
        }
        return `${duration} seconds`;
    }, [duration]);

    const disabledTime = useCallback(
        () =>
            !startTime || !endTime
                ? {}
                : {
                      disabledHours: () =>
                          Array.from({ length: 24 }, (_, i) => i).filter(
                              (i) => i < moment(startTime).hour() || i > moment(endTime).hour()
                          ),
                      // Disable minutes when selected hour is the startTime hour or when selected hour is the endTime hour
                      disabledMinutes: (selectedHour) =>
                          Array.from({ length: 60 }, (_, i) => i).filter(
                              (i) =>
                                  (selectedHour === moment(startTime).hour() &&
                                      i < moment(startTime).minute()) ||
                                  (selectedHour === moment(endTime).hour() &&
                                      i > moment(endTime).minute())
                          ),
                      disabledSeconds: (selectedHour, selectedMinute) =>
                          Array.from({ length: 60 }, (_, i) => i).filter(
                              (i) =>
                                  (selectedHour === moment(startTime).hour() &&
                                      selectedMinute === moment(startTime).minute() &&
                                      i < moment(startTime).second()) ||
                                  (selectedHour === moment(endTime).hour() &&
                                      selectedMinute === moment(endTime).minute() &&
                                      i > moment(endTime).second())
                          ),
                  },
        [startTime, endTime]
    );

    if (!startTime || !endTime || !selectedStartTime || !selectedEndTime) {
        return null;
    }

    return (
        <div style={{ padding: '0px 20px' }}>
            <Row style={{ marginTop: '30px' }} gutter={[16, 16]}>
                <Col span={18}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '30px',
                            alignItems: 'center',
                        }}
                    >
                        <Title level={4} style={{ marginBottom: 0 }}>
                            Request Video Clips
                        </Title>
                        <Select
                            value={selectedChannel}
                            onChange={(value) => onChannelChange(value)}
                            options={channels.map((channel) => ({
                                value: channel.name,
                                label: channel.displayName,
                            }))}
                            disabled={requestingVideo}
                        />
                    </div>
                    <Descriptions
                        column={2}
                        labelStyle={{
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '90px',
                        }}
                        contentStyle={{ justifyContent: 'flex-end' }}
                    >
                        <Descriptions.Item label="Start Time">
                            <TimePicker
                                css={styles.timePicker}
                                value={moment(selectedStartTime)}
                                showNow={false}
                                hideDisabledOptions
                                disabledTime={disabledTime}
                                onChange={(time) => setSelectedStartTime(time.toDate())}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="End Time">
                            <TimePicker
                                css={styles.timePicker}
                                value={moment(selectedEndTime)}
                                showNow={false}
                                hideDisabledOptions
                                disabledTime={disabledTime}
                                onChange={(time) => setSelectedEndTime(time.toDate())}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <div css={styles.actions}>
                        <Text type={!durationAllowed ? 'danger' : 'secondary'}>
                            {duration >= 0
                                ? durationAllowed
                                    ? `Duration: ${durationDisplay}`
                                    : `Duration: ${durationDisplay} (Max 10 minutes)`
                                : 'Please correct the start and end time'}
                        </Text>
                        <Tooltip
                            title={
                                !durationAllowed
                                    ? 'Please correct the requested start and end time'
                                    : undefined
                            }
                        >
                            <Button
                                type="primary"
                                icon={<CloudUploadOutlined />}
                                loading={requestingVideo}
                                onClick={() =>
                                    handleRequestVideo(selectedStartTime, selectedEndTime, [
                                        parseInt(name, 10),
                                    ])
                                }
                                disabled={!durationAllowed}
                                // style={{ marginRight: '45px' }}
                            >
                                Download Video
                            </Button>
                        </Tooltip>
                    </div>
                </Col>

                <Col span={6} style={{ textAlign: 'center' }}>
                    <Spin spinning={loadingImage}>
                        <Space direction="vertical">
                            <img
                                src={preview}
                                alt="Channel preview"
                                css={styles.preview}
                                onError={(ev) => (ev.target.src = '/imgs/ic_nosignal.png')}
                            />
                            <Text type="secondary">Channel Preview</Text>
                        </Space>
                    </Spin>
                </Col>
            </Row>

            <div css={styles.timeline}>
                <TimeRange
                    timelineInterval={[new Date(startTime), new Date(endTime)]}
                    disabledIntervals={gaps}
                    selectedInterval={[new Date(selectedStartTime), new Date(selectedEndTime)]}
                    onUpdateCallback={() => {}}
                    onChangeCallback={(selectedInterval) => {
                        setSelectedStartTime(selectedInterval[0].valueOf());
                        setSelectedEndTime(selectedInterval[1].valueOf());
                    }}
                    ticksNumber={10}
                    step={1000}
                    showTooltip
                    formatTick={(ms) => moment(ms).tz(timezone).format('HH:mm:ss')}
                    formatTooltip={(ms) => moment(ms).tz(timezone).format('HH:mm:ss')}
                    tooltipTag="Time:"
                />
            </div>
        </div>
    );
};

export default Channel;
