/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import Ant Design components
import { Layout } from 'antd';

// Import stylesheet
import styles from './styles';

// Import components
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';

// Import Content component
const { Content } = Layout;

/**
 * Display full screen view under predefined path names,
 * no footer will be shown and content margin will be removed.
 * @param {*} pathname Page path name
 * @returns true if a full screen view should be displayed, otherwise false
 */
const showFullScreen = (pathname) => {
    switch (pathname) {
        case 'fleetmap/real-time':
        case 'history/map':
            return true;
        default:
            return false;
    }
};

const AppLayout = (props) => {
    // Retrieve path name and check if full screen view needs to be displayed
    const { pathname } = props;
    const shouldDisplayFS = showFullScreen(pathname);

    return (
        <Layout css={styles.layout}>
            <AppHeader pathname={pathname} />

            <Content css={styles.contentPageHeader}>{props.children}</Content>

            {!shouldDisplayFS && <AppFooter />}
        </Layout>
    );
};

export default AppLayout;
