// Import library
import { css } from '@emotion/core';

const styles = {
    footer: css({
        textAlign: 'center',
        color: 'rgba(0,0,0,.45)',
    }),
    contact: css({
        display: 'flex',
        justifyContent: 'center',
    }),
    about: css({ margin: '8px 0px' }),
    footerLinks: css({
        display: 'flex',
        justifyContent: 'center',
        a: {
            margin: '0 20px',
            color: 'rgba(0,0,0,.45)',
        },
    }),
};

export default styles;
