// Import libraries
import { css } from '@emotion/core';

// Import presets
import { content } from '../../styles/presets';

// Prepare stylesheet
const styles = {
    contentWrapper: css({
        ...content,
        maxWidth: '1280px',
        '.ant-empty-description': {
            color: '#C8C8C8',
        },
    }),
    resultsPanel: css({
        margin: '30px 0px',
    }),
};

export default styles;
