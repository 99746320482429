// Import libraries
import { css } from '@emotion/core';

// Import styles
import { colors } from '../../../../styles/presets';

// Prepare stylesheet
const styles = {
    header: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 30px',
    }),
    back: css({ paddingLeft: 0 }),
    channel: css({ textAlign: 'center' }),
    preview: css({
        width: '150px',
        height: '150px',
        border: '2px solid transparent',
        backgroundColor: '#F8F7F7',
        cursor: 'pointer',
        transition: 'all 0.3s ease',

        // Show a clear clickable effect on hover
        '&:hover': {
            border: `2px solid ${colors.primary}`,
            boxShadow: `0 0 10px ${colors.primary}`,
            transform: 'scale(1.05)',
        },
    }),
};

export default styles;
